import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../helpers/product";
import ProductImageGallery from "../../components/product/ProductImageGallery";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb";
import ProductImageFixed from "../../components/product/ProductImageFixed";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  currency,
  cartItems,
  wishlistItems,
  compareItems,
  updateFather
}) => {
  const [foto, setFoto] = useState("")
  const [updateBannerAfter, setUpdateBannerAfter] = useState(false)
  const [giorni,setGiorni] = useState("")

  const wishlistItem = wishlistItems.filter(
    wishlistItem => wishlistItem.id === product.id
  )[0];
  const compareItem = compareItems.filter(
    compareItem => compareItem.id === product.id
  )[0];
  const { addToast } = useToasts();

  const discountedPrice = getDiscountPrice(product.price, product.discount);
  const finalProductPrice = +(product.price * currency.currencyRate).toFixed(2);
  const finalDiscountedPrice = +(
    discountedPrice * currency.currencyRate
  ).toFixed(2);
  const changeImage = (color) => {

    for (let i = 0; i < product.variation.length; i++) {
      if (product.variation[i].color == color) {
        if (product.variation[i].image != "") {
          setFoto(product.variation[i].image)

        }
      }
    }
  }
  const setimage = () => {
    setFoto("")
  }
  const UpdateBannerFuncion = (giorni) =>{
    console.log("CIAO FROM PRODUCT IMAGE")
    setGiorni(giorni)
    setUpdateBannerAfter(true)
  }


  return (
    <div
      style={{ backgroundColor: "white" }}
      className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
        }`}
    >
      <div className="container">
        {updateBannerAfter &&
          <div className="bannerAfter">Attenzione stai per acquistare un prodotto che è disponibile in {giorni} GIORNI</div>
        }
        <div className="row">


          <div className="col-lg-6 col-md-6">
            {/* product image gallery */}
            {galleryType === "leftThumb" ? (


              <ProductImageGallerySideThumb
                product={product}
                thumbPosition="left"
              />

            ) : galleryType === "rightThumb" ? (
              <ProductImageGallerySideThumb product={product} />
            ) : galleryType === "fixedImage" ? (
              <ProductImageFixed product={product} />
            ) : (
              <ProductImageGallery product={product} image={foto} setnull={setimage}
              />
            )}

          </div>
          <div className="col-lg-6 col-md-6">
            {/* product description info */}
            <ProductDescriptionInfo
              product={product}
              discountedPrice={discountedPrice}
              currency={currency}
              finalDiscountedPrice={finalDiscountedPrice}
              finalProductPrice={finalProductPrice}
              cartItems={cartItems}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              addToast={addToast}
              change={changeImage}
              setUpdateBannerAfter={UpdateBannerFuncion}
              updateFather={updateFather}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData,
    wishlistItems: state.wishlistData,
    compareItems: state.compareData
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
