const buildRegEx = function (str, keywords) {
  return new RegExp("(?=.*?\\b" +
    keywords
      .split(" ")
      .join(")(?=.*?\\b") +
    ").*",
    "i"
  );
}
const search_function = function (key, lista) {
  console.log("searchfunction", lista)
  var result = []
  for (let i = 0; i < lista.length; i++) {
    if (buildRegEx(lista[i].name, key).test(lista[i].name) || buildRegEx(lista[i].shortDescription, key).test(lista[i].shortDescription) || buildRegEx(lista[i].tag[0].toLowerCase(), key.toLowerCase()).test(lista[i].tag[0].toLowerCase())) {
      result.push(lista[i])
    }
  }
  return result
}
const setProductFromDatabase = function (response, imagenondisp) {
  var appoggio = []
  console.log("dashboard",response.data)
  for (let i = 0; i < response.data.length; i++) {
    console.log("dashbord iesimo",i)
    var personalizzazione_label = ""
    var descrizione_personalizzazione = ""

    if (response.data[i].descrizione_personalizzazione == null) {
      descrizione_personalizzazione = "Non disponibile"
    }
    else {
      descrizione_personalizzazione = response.data[i].descrizione_personalizzazione
    }

    //funzione che genera un json dove la chiave è il colore è il valore è l'indice. ESSA E' NECESSARIA PER VISUALIZZARE BENE LA FOTO DEL COLORE GIUSTO NEL CARELLO
    var json = {}
    for (let c = 0; c < response.data[i].indiciColori.length; c++) {
      json[response.data[i].indiciColori[c].color] = response.data[i].indiciColori[c].indice
    }
    var isGratis = false
    //verificare se è gratis
    if (response.data[i].gratis == true) {
      isGratis = true
    }
    else {
      isGratis = false
    }
    var costo_pers = 0
    //costo PERSONALIZZAZIONE
    if (response.data[i].costo_personalizzazione == null) {
      costo_pers = 0
    }
    else {
      costo_pers = response.data[i].costo_personalizzazione
    }
    //dichiarazioni variabili temporanee
    var valore_offerta = 0
    var vettore_immagini = []
    var vettore_variations = []
    //controlli pre conversione
    // 1) se offerta è null -> valore_offera= 0 || ho fatto questo controllo perchè discount non accetta null
    if (response.data[i].offerta == null) {
      valore_offerta = 0
    }
    else {
      valore_offerta = response.data[i].offerta
    }

    // 2) faccio questo controllo per andarmi a prendere tutte le img indipendetemente dal colore
    for (let j = 0; j < response.data[i].coloriDisp.length; j++) {
      //console.log("attenzione",response.data[i].coloriDisp[j].image)
      if (response.data[i].coloriDisp[j].image != "") {
        for (let k = 0; k < response.data[i].coloriDisp[j].image.length; k++) {
          vettore_immagini.push(response.data[i].coloriDisp[j].image[k])
        }
      }
      else {

        vettore_immagini.push(imagenondisp)
      }
    }
    if (response.data[i].personalizzazione == true) {
      personalizzazione_label = "{}"

    }
    else if (response.data[i].personalizzazione == false) {
      personalizzazione_label = "Non Supportata"
    }

    

    for (let m = 0; m < response.data[i].coloriDisp.length; m++) {
      if (response.data[i].coloriDisp[m].image.length != 0) {

        var size = []
        for (let k = 0; k < Object.keys(response.data[i].coloriDisp[m].size).length; k++) {
          if (response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].prenotazione=="Si") {
          var single_size = {
            "name": Object.keys(response.data[i].coloriDisp[m].size)[k],
            "stock": response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].stock,
            "prenotazione":true,
            "giorni":response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].giorni
          }
          size.push(single_size)
        }else{
          var single_size = {
            "name": Object.keys(response.data[i].coloriDisp[m].size)[k],
            "stock": response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].stock,
            "prenotazione":false
          }
          size.push(single_size)
        }
        }
        var struttura_variation = {
          "color": response.data[i].coloriDisp[m].color,
          "image": response.data[i].coloriDisp[m].image[0],
          "size": size
        }


      }
      else {

        var size = []
        console.log("DASHBOARD",Object.keys(response.data[i].coloriDisp[m]))
        for (let k = 0; k < Object.keys(response.data[i].coloriDisp[m].size).length; k++) {
          console.log("dashboard",response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]])
          
          if (response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].prenotazione=="Si") {
            var single_size = {
              "name": Object.keys(response.data[i].coloriDisp[m].size)[k],
              "stock": response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].stock,
              "prenotazione":true,
              "giorni":response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].giorni
            }
            size.push(single_size)
          } else {

            var single_size = {
              "name": Object.keys(response.data[i].coloriDisp[m].size)[k],
              "stock": response.data[i].coloriDisp[m].size[Object.keys(response.data[i].coloriDisp[m].size)[k]].stock,
              "prenotazione":false
            }
            size.push(single_size)

          }
          
        }

        var struttura_variation = {
          "color": response.data[i].coloriDisp[m].color,
          "image": imagenondisp,
          "size": size
        }



      }


      vettore_variations.push(struttura_variation)
    }


    appoggio.push({
      "id": response.data[i].idArticolo,
      "sku": "",
      "name": response.data[i].nomeArticolo,
      "price": Number(response.data[i].prezzo).toFixed(2),
      "discount": valore_offerta,
      "offerEnd": "", //"October 5, 2020 12:11:00",
      "new": response.data[i].ultimiArrivi,
      "rating": "",//4,
      "saleCount": "",
      "category": [response.data[i].categoria],
      "tag": [response.data[i].marca],
      "variation": vettore_variations,
      "image": vettore_immagini,
      "shortDescription": response.data[i].descrizione,
      "fullDescription": "",
      "IndexColor": json,
      "correlated": response.data[i].correlati,
      "isPersonalized": response.data[i].personalizzazione,
      "schedatecnica": response.data[i].schedaTecnica,
      "personalizzazione": personalizzazione_label,
      "isGratis": isGratis,
      "sottocategoria": response.data[i].sottoCategoria,
      "costopersonalizzazione": costo_pers,
      "personalizzazione_descrizione": descrizione_personalizzazione,
      "ispersonalizzateClient": false
    }

    )
  }
  return appoggio

}
export {
  search_function,
  setProductFromDatabase
}