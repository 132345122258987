import React, { useEffect } from "react";
import MobileMenuSearch from "./sub-components/MobileSearch";
import MobileNavFilter from "./sub-components/MobileNavFilter";
import MobileLangCurChange from "./sub-components/MobileLangCurrChange";
import MobileWidgets from "./sub-components/MobileWidgets";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import PropTypes from "prop-types";
import {
  getIndividualCategories,
  getIndividualTags,
  getIndividualColors,
  getProductsIndividualSizes
} from "../../helpers/product";
const MobileFilter = (products, getSortParams, sideSpaceClass,view ) => {

  const uniqueCategories = getIndividualCategories(products.products);
  const uniqueColors = getIndividualColors(products.products);
  const uniqueSizes = getProductsIndividualSizes(products.products);
  const uniqueTags = getIndividualTags(products.products);
 

  
  useEffect(() => {

    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", e => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  });

  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-filter"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvas-mobile-filter" id="offcanvas-mobile-filter">
      <button
        className="offcanvas-menu-close"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="pe-7s-close"></i>
      </button>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          {/* mobile search */}
          

          {/* mobile nav menu */}
          <MobileNavFilter view={view} products={products} getSortParams={getSortParams} sideSpaceClass={sideSpaceClass} uniqueTags={uniqueTags} uniqueColors={uniqueColors} uniqueCategories={uniqueCategories}/>

          {/* mobile language and currency 
          <MobileLangCurChange />
*/}
          {/* mobile widgets */}
          <MobileWidgets />
        </div>
      </div>
    </div>
  );
};



MobileFilter.propTypes = {
  getSortParams: PropTypes.func,
  products: PropTypes.array,
  sideSpaceClass: PropTypes.string
};

export default MobileFilter;
