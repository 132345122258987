import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import { multilanguage } from "redux-multilanguage";
import { configWhatsApp } from "../../configs/AppConfig"
import Swal from 'sweetalert2';
import { HashLink } from 'react-router-hash-link';
const FooterOne = ({
                     strings,
                     backgroundColorClass,
                     spaceTopClass,
                     spaceBottomClass,
                     spaceLeftClass,
                     spaceRightClass,
                     containerClass,
                     extraFooterClass,
                     sideMenu
                   }) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);
  const [resize, setresize] = useState(null);
  const updateSize = () => {

    if (window.innerWidth <= 1023 || window.innerHeight <= 481) {

      setresize(true)

    }
    else {
      setresize(false)
    }

  }
  const call = (number) => {

    window.open('tel:' + number + '');
  }
  useEffect(() => {
    if (window.innerWidth <= 1023 || window.innerHeight <= 136) {
      setresize(true)
    }
    else {
      setresize(false)
    }
    window.addEventListener('resize', updateSize);
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (

      <footer
          className={`footer-area ${backgroundColorClass ? backgroundColorClass : ""
          } ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""
          } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""
          } ${spaceRightClass ? spaceRightClass : ""}`}
      >



        {!resize ?
            <div className="row" style={{ marginRight: "0px", marginLeft: "0px" }}>
              <div
                  className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
                  }`}
                  style={{ paddingTop: "50px" }}
              >
                <div className="footer-widget mb-30 ml-30">
                  <div className="footer-title">
                    <h3>INFORMAZIONI</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a href={"https://arredobimbo.com"}>
                          Arredo Bimbo S.R.L.
                        </a>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/about"}>{strings['chisiamo']}</Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/contact"}>
                          {strings['dovesiamo']}
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/contact"}>
                          {strings['contatti']}
                        </Link>
                      </li>

                    </ul>
                  </div>

                </div>
                <div className="linea-footer"></div>
                <div className="footer-widget mb-30 ml-30">
                  <div className="footer-title">
                    <h3>Punti Vendita</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <div className="line-footer-text" >
                          Via Roma, 17 - 80057 - Sant'Antonio Abate - Napoli.
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text">
                          dal lunedì al sabato:
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text" >
                          dalle 09:30 alle 13:00
                        </div>
                      </li>

                      <li>
                        <div className="line-footer-text">
                          dalle 16:30 alle 20:00
                        </div>
                      </li>

                    </ul>
                  </div>

                </div>
              </div>

              <div
                  className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
                  }`}
                  style={{ paddingTop: "50px" }}
              >
                <div className="footer-widget mb-30 ml-30">
                  <div className="footer-title">
                    <h3>Assistenza Online</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <div className="line-footer-text-tel" onClick={() => call("3347259681")}>
                          Telefono: +39 3347259681
                        </div>
                      </li>
                      <li>
                        <a href={configWhatsApp} >
                          Contattaci su WhatsApp (dal Lunedì al Sabato dalle 9:30 alle 13 e dalle 16:30 alle 20:00)
                        </a>
                      </li>



                    </ul>
                  </div>

                </div>
                <div className="linea-footer"></div>
                <div className="footer-widget mb-30 ml-30">
                  <div className="footer-title">
                    <h3>Assistenza Negozio</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <div className="line-footer-text-tel" onClick={() => call("0818734254")}>
                          Telefono: +39 0818734254 (dal Lunedì al Sabato dalle 9:30 alle 13 e dalle 16:30 alle 20:00)
                        </div>
                      </li>



                    </ul>
                  </div>

                </div>
                <div className="linea-footer"></div>
                <div className="footer-widget mb-30 ml-30">

                  <div className="footer-title">
                    <h3>{strings['followus']}</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a
                            href="//www.facebook.com/arredobimbo.savino"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                            href="https://www.tiktok.com/@martinamariaaprea?lang=it-IT"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          TikTok
                        </a>
                      </li>
                      <li>
                        <a
                            href="//www.instagram.com/arredo_bimbo_savino_/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </li>

                    </ul>
                  </div>


                </div>
              </div>
              <div
                  className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"
                  }`}
                  style={{ paddingTop: "50px" }}
              >

                <div
                    className={`${sideMenu
                        ? "footer-widget mb-30 ml-95"
                        : "footer-widget mb-30 ml-50"
                    }`}
                >
                  <div className="footer-title">
                    <h3>{strings['centroassistenza']}</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#condizionespedizione"}>{strings['shippingconditions']}</HashLink>
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#terminidipagamento"}>
                          {strings['termsofpayment']}
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#politicacancellazione"}>Politica Cancellazioni e Rimborsi</HashLink >
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#condizionidivedita"}>{strings['conditionsofsale']}</HashLink>
                      </li>
                      <li>
                        <a href={"https://www.iubenda.com/privacy-policy/43669643/full-legal"} target="_blank">
                          Informazioni sulla Privacy
                        </a>

                      </li>
                      <li>
                        <a href={"https://www.iubenda.com/privacy-policy/43669643/cookie-policy"} target="_blank">
                          {strings['cookiepolicy']}
                        </a>

                      </li>
                    </ul>
                  </div>
                </div>

                <div className="linea-footer"></div>
                <div
                    className={`${sideMenu
                        ? "footer-widget mb-30 ml-145"
                        : "footer-widget mb-30 ml-50"
                    }`}
                >
                  <div className="footer-title">
                    <h3>Informazioni</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <div className="line-footer-text">
                          Email: arredobimbo@gmail.com
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text">
                          P.Iva: 05881661218
                        </div>
                      </li>




                    </ul>
                  </div>
                </div>
              </div>
              <div
                  className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-3 col-sm-4"
                  }`}
                  style={{ backgroundColor: "#007bff", paddingTop: "50px" }}

              >
                <div style={{ paddingLeft: "40px", paddingRight: "40px" }} className="footer-widget mb-30 ml-30">







                  <div className="footer-title" style={{ marginTop: "70px" }}>
                    <h3>ISCRIVITI ALLA NEWSLETTER</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li style={{ marginBottom: "50px" }}>
                        <div className="line-footer-text">
                          Ricevi tutte le ultime novità sulle nostre
                          Vendite e Promozioni. Iscriviti subito alla newsletter.
                        </div>
                      </li>


                      <li>
                        <form action="https://arredobimbo.us14.list-manage.com/subscribe/post?u=a7f418498c1a212e1b79e2b16&amp;id=6fae679abb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

                          <input value="Iscriviti" className="button-footer-newsletter" type="submit" />

                        </form>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            :
            <div className="row" style={{ marginRight: "0px", marginLeft: "0px" }}>
              <div className="col-xl-0 col-lg-0 col-md-0 col-0" style={{ paddingTop: "50px" }} >
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12 col-12" style={{ paddingTop: "50px", paddingRight: "0px", paddingLeft: "0px" }}>



                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>INFORMAZIONI</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <a href={"https://arredobimbo.com"}>
                          Arredo Bimbo S.R.L.
                        </a>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/about"}>{strings['chisiamo']}</Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/contact"}>
                          {strings['dovesiamo']}
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + "/contact"}>
                          {strings['contatti']}
                        </Link>
                      </li>

                    </ul>
                  </div>


                </div>
                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>Punti Vendita</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <div className="line-footer-text" >
                          Via Roma, 17 - 80057 - Sant'Antonio Abate - Napoli.
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text">
                          dal lunedì al sabato:
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text" >
                          dalle 09:30 alle 13:00
                        </div>
                      </li>

                      <li>
                        <div className="line-footer-text">
                          dalle 16:30 alle 20:00
                        </div>
                      </li>

                    </ul>
                  </div>

                </div>
                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>Assistenza Online</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <div className="line-footer-text-tel" onClick={() => call("3347259681")}>
                          Telefono: +39 3347259681
                        </div>
                      </li>
                      <li>
                        <a href={configWhatsApp} >
                          Contattaci su WhatsApp
                        </a>
                      </li>
                      <li>
                        <div className="line-footer-text">
                          dal lunedì al sabato:
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text" >
                          dalle 09:30 alle 13:00
                        </div>
                      </li>

                      <li>
                        <div className="line-footer-text">
                          dalle 16:30 alle 20:00
                        </div>
                      </li>



                    </ul>
                  </div>


                </div>


                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>Assistenza Negozio</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <div className="line-footer-text-tel" onClick={() => call("0818734254")}>
                          Telefono: +39 0818734254
                        </div>
                      </li>

                      <li>
                        <div className="line-footer-text">
                          dal lunedì al sabato:
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text" >
                          dalle 09:30 alle 13:00
                        </div>
                      </li>

                      <li>
                        <div className="line-footer-text">
                          dalle 16:30 alle 20:00
                        </div>
                      </li>

                    </ul>
                  </div>

                </div>
                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>Informazioni</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <div className="line-footer-text">
                          Email: arredobimbo@gmail.com
                        </div>
                      </li>
                      <li>
                        <div className="line-footer-text">
                          P.Iva: 05881661218
                        </div>
                      </li>




                    </ul>
                  </div>


                </div>
                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>{strings['centroassistenza']}</h3>
                  </div>
                  <div className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#condizionespedizione"}>{strings['shippingconditions']}</HashLink>
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#terminidipagamento"}>
                          {strings['termsofpayment']}
                        </HashLink>
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#politicacancellazione"}>Politica Cancellazioni e Rimborsi</HashLink >
                      </li>
                      <li>
                        <HashLink to={process.env.PUBLIC_URL + "/centroassistenza/#condizionidivedita"}>{strings['conditionsofsale']}</HashLink>
                      </li>
                      <li>
                        <a href={"https://www.iubenda.com/privacy-policy/43669643/full-legal"} target="_blank">
                          Informazioni sulla Privacy
                        </a>
                      </li>
                      <li>
                        <a href={"https://www.iubenda.com/privacy-policy/43669643/cookie-policy"} target="_blank">
                          {strings['cookiepolicy']}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer-widget mb-30 ml-30" style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                  <div className="footer-title">
                    <h3>{strings['followus']}</h3>
                  </div>
                  <div className="footer-list">
                    <ul>
                      <li>
                        <a
                            href="//www.facebook.com/arredobimbo.savino"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          Facebook
                        </a>
                      </li>
                      <li>
                        <a
                            href="https://www.tiktok.com/@martinamariaaprea?lang=it-IT"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          TikTok
                        </a>
                      </li>
                      <li>
                        <a
                            href="//www.instagram.com/arredo_bimbo_savino_/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                          Instagram
                        </a>
                      </li>

                    </ul>
                  </div>
                </div>
                <div className="footer-widget mb-30 ml-30" style={{ marginBottom: "0px", backgroundColor: "#007bff", paddingTop: "50px", display: "flex", flexDirection: "column", alignItems: " center" }} >
                  <div className="footer-title" style={{ paddingTop: "5px" }}>
                    <h3>ISCRIVITI ALLA NEWSLETTER</h3>
                  </div>
                  <div style={{ paddingLeft: "50px", paddingRight: "50px", marginBottom: "16px" }} className="footer-list">
                    <ul style={{ display: "flex", flexDirection: "column", alignItems: " center" }}>
                      <li >
                        <div className="line-footer-text">
                          Ricevi tutte le ultime novità sulle nostre
                          Vendite e Promozioni. Iscriviti subito alla newsletter.
                        </div>
                      </li>


                      <li>
                        <form action="https://arredobimbo.us14.list-manage.com/subscribe/post?u=a7f418498c1a212e1b79e2b16&amp;id=6fae679abb" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>

                          <input value="Iscriviti" className="button-footer-newsletter" type="submit" />

                        </form>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-0 col-lg-0 col-md-0 col-0" style={{ paddingTop: "50px" }}>
              </div>

            </div>
        }

        <button
            className={`scroll-top ${scroll > top ? "show" : ""}`}
            onClick={() => scrollToTop()}
        >
          <i className="fa fa-angle-double-up"></i>
        </button>
      </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
  strings: PropTypes.object
};

export default multilanguage(FooterOne);