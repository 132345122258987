import React from "react";
import { NavLink } from "react-router-dom";
import { Breadcrumbs } from "react-breadcrumbs-dynamic";

const BreadcrumbV2 = () => {
  return (
    <div className="breadcrumb-area pt-15 pb-15 breadcrumpmaffle">
      <div className="container">
        <div style={{color:"white !important"}} className="breadcrumbmaffle-content text-center" >
          <Breadcrumbs
            separator={<span style={{color:"white !important"}}>/</span>}
            item={NavLink}
            finalItem={"span"}
          />
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbV2;
