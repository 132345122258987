import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import MenuCart from "./sub-components/MenuCart";
import { deleteFromCart } from "../../redux/actions/cartActions";
import fire from "../../firebase/firebase"
import { multilanguage } from "redux-multilanguage";
import { useHistory } from "react-router-dom";
import { Redirect } from 'react-router-dom'
//import { Link } from 'react-router-dom';

const IconGroup = ({
  currency,
  cartData,
  wishlistData,
  compareData,
  deleteFromCart,
  iconWhiteClass,
  strings
}) => {
  const [logged, setlogged] = useState(false);
  const [resize, setresize] = useState(null);
  const [searchLink, setsearchLink] = useState("");
  let history = useHistory();
  const handleClick = e => {
    e.currentTarget.nextSibling.classList.toggle("active");
  };
  /*
  const handleSearch = e => {
    e.preventDefault()
    return <Link to='/shop' />
  };
*/
  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };
  const logout = () => {
    fire.auth().signOut()
  }
  const changeSearch = () => {
    setsearchLink(document.getElementById("idsearchall").value)
  }

  useEffect(() => {

    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        setlogged(true)
      } else {
        setlogged(false)
      }
    });
  }, []);




  return (
    <div
      className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}
    >
      {!window.location.pathname.includes("shop") &&
        <div className="same-style header-search d-none d-lg-block">

          <button className="search-active" onClick={e => handleClick(e)}>
            <i className="pe-7s-search" />
          </button>

          <div className="search-content">
            <form action="#">
              <input type="text" id="idsearchall" onChange={() => changeSearch()} placeholder="Cerca..." />

              <Link to={process.env.PUBLIC_URL + '/shop?fromsearch=true&search=' + searchLink} >
                <button className="button-search" >
                  <i className="pe-7s-search" />

                </button>
              </Link>

            </form>
          </div>

        </div>
      }
      <div className="same-style account-setting  ">
        <button
          className="account-setting-active"
          onClick={e => handleClick(e)}
        >
          <i className="pe-7s-user-female" />
        </button>
        <div className="account-dropdown">
          <ul>
            {!logged &&
              <li>
                <Link to={process.env.PUBLIC_URL + "/login-register"}>{strings['loginregi']}</Link>
              </li>
            }
            {/* 
            <li>
              <Link to={process.env.PUBLIC_URL + "/login-register"}>
                
              </Link>
            </li>
            */}
            {logged &&
              <li>
                <Link to={process.env.PUBLIC_URL + "/my-account"}>
                  {fire.auth().currentUser.displayName}
                </Link>
              </li>
            }
            {logged &&

              <li>
                <Link to={process.env.PUBLIC_URL + "/shopping"}>
                  {strings['ImieiAcquisti']}
                </Link>
              </li>
            }
            {logged &&

                <li>
                  <Link  to={process.env.PUBLIC_URL + "/crea-lista-nascita"}>
                    Le mie liste nascita
                  </Link>
                </li>
            }
            {logged &&

              <li>
                <Link onClick={() => logout()} to={process.env.PUBLIC_URL + "/"}>
                  Logout
                </Link>
              </li>
            }

          </ul>
        </div>
      </div>
      <div style={{ marginLeft: "8px" }} className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">
            {wishlistData && wishlistData.length ? wishlistData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareData && compareData.length ? compareData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style cart-wrap d-none d-lg-block">
        <button className="icon-cart" onClick={e => handleClick(e)}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart
          cartData={cartData}
          currency={currency}
          deleteFromCart={deleteFromCart}
        />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartData && cartData.length ? cartData.length : 0}
          </span>
        </Link>
      </div>
      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div >
  );
};

IconGroup.propTypes = {
  cartData: PropTypes.array,
  compareData: PropTypes.array,
  currency: PropTypes.object,
  iconWhiteClass: PropTypes.string,
  deleteFromCart: PropTypes.func,
  wishlistData: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartData: state.cartData,
    wishlistData: state.wishlistData,
    compareData: state.compareData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteFromCart: (item, addToast) => {
      dispatch(deleteFromCart(item, addToast));
    }
  };
};
export default multilanguage(connect(mapStateToProps, mapDispatchToProps)(IconGroup));
