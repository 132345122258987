import PropTypes from "prop-types";
import React from "react";
import { setActiveSort } from "../../helpers/product";
import { multilanguage } from "redux-multilanguage";
const ShopTag = ({ tags, getSortParams,strings }) => {
  console.log("tags di shoptag",tags)
  console.log("GetSortParams di ShopTag",getSortParams)
  
  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">{strings['marchi']} </h4>
      <div className="sidebar-widget-tag mt-25">
        {tags ? (
          <ul>
            {tags.map((tag, key) => {
              return (
                <li key={key}>
                  <button
                    onClick={e => {
                      getSortParams("tag", tag);
                      setActiveSort(e);
                    }}
                  >
                    {tag}
                  </button>
                </li>
              );
            })}
          </ul>
        ) : (
          "No tags found"
        )}
      </div>
    </div>
  );
};

ShopTag.propTypes = {
  getSortParams: PropTypes.func,
  tags: PropTypes.array,
  strings: PropTypes.object
};

export default multilanguage(ShopTag);
