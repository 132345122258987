import axios from "axios"
import { FormRow } from "react-bootstrap/Form"
//import { IP } from '../configs/IPConfig'
import { env } from "../configs/EnviromentsConfigs"
//import { IP } from '../configs/IPConfig'
import fire from "../firebase/firebase"
import imagenondisp from '../assets/img/prodottonondisponibile/prodottonondisp.png'
import { setProductFromDatabase } from './repetitiveFunctions.js'
const api = require('./api')

const IP = env.IP
//const IP = env.IP;
const createToken = async () => {
    const user = fire.auth().currentUser;
    const token = user && (await user.getIdToken());
    const payloadHeader = {
        headers: {
            "Authorization": "Bearer " + token,
        },
    };
    return payloadHeader;
}

const getArticoli = function (callback) {
    axios.get(IP + '/getListaProdotti').
        then((response) => {

            var appoggio = setProductFromDatabase(response, "https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/imgnndsp.png?alt=media&token=c3bc0442-f020-4b65-858c-eff5ae750acc")
            callback(appoggio)
        }).catch(err => {
            console.log(err)
        })
}
const getfoto = function (callback) {
    axios.get(IP + '/public/foto').
        then((response) => {
            callback(response)
        }).catch(err => {
            console.log(err)
        })
}

const getCategorie = function (input, callback) {
    axios.post(IP + "/getCategoria", { categoria: input }).then(res => {
        var vettore = setProductFromDatabase(res, "https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/imgnndsp.png?alt=media&token=c3bc0442-f020-4b65-858c-eff5ae750acc")
        callback(vettore)
    }).catch(err => {
        console.log(err)
    })
}

const getSottoCategorie = function (input, callback) {
    axios.post(IP + "/getSottocategoria", { sottocategoria: input }).then(res => {
        var vettore = setProductFromDatabase(res, "https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/imgnndsp.png?alt=media&token=c3bc0442-f020-4b65-858c-eff5ae750acc")
        callback(vettore)
    }).catch(err => {
        console.log(err)
    })
}
const whoiam = async (callback) => {
    const header = await createToken();
    axios.get(IP + "/whoiam", header).then(res => {
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}
const setInformation = async (information, callback) => {

    const header = await createToken();

    axios.post(IP + "/setInfo", information, header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}
const modifyInfo = async (information, callback) => {

    const header = await createToken();

    axios.post(IP + "/modifyInfo", information, header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}
const deleteaddress = async (information, callback) => {

    const header = await createToken();

    axios.post(IP + "/deleteaddress", information, header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}
const updateadresscall = async (information, callback) => {

    const header = await createToken();

    axios.post(IP + "/updateaddress", information, header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}

const addaddresscall = async (information, callback) => {

    const header = await createToken();

    axios.post(IP + "/addaddress", information, header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        callback(false)
        console.log(err)
    })
}
const setInformationEmail = async (callback) => {

    const header = await createToken();
    axios.get(IP + "/setInfoEmail", header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(true)
        }
        else {
            callback(false)
        }
    }).catch(err => {
        console.log(err)
    })
}
const getAllinformation = async (callback) => {

    const header = await createToken();

    axios.get(IP + "/getAllinformation", header).then(res => {
        console.log(res)
        if (res.status == 200) {
            callback(res)
        }
        else {
            callback("FAILED")
        }
    }).catch(err => {
        callback("FAILED")
    })
}

//AXIOS PER LE LISTE REGALO
const doListaRegalo = async (values, callback) => {
    console.log("AXIOS CALL : ", values)
    const header = await createToken();
    axios.post(IP + "/setListaRegalo", { values }, header).
        then(res => {

            callback(res)
        })
}

const updateListaRegalo = async (values, callback) => {
    console.log("AXIOS CALL : ", values);
    try {
        const header = await createToken();
        const response = await axios.post(IP + "/updateListaRegalo", { values }, header);
        callback(response);
    } catch (error) {
        // Handle any errors that occurred during the API call
        console.error(error);
    }
};


const checkListe = async (callback) => {
    console.log("SONO IN CHECKLISTE")
    const header = await createToken();

    axios.get(IP + '/getListeUtente', header).
        then((response) => {
            let appoggio = []
            appoggio = response.data
            callback(appoggio)
        }).catch(err => {
            console.log(err)
            callback("FAILED")
        })
}

const deleteCard = async (idlista, callback) => {
    const header = await createToken();
    axios.post(IP + '/deleteListaRegalo', { codiceLista: idlista }, header).
        then((response) => {

            callback(response)
        }).catch(err => {
            console.log(err)
        })
}

const checkout = async (body, callback) => {
    const header = await createToken();
    axios.post(IP + '/createcheckoutsession', body, header, {
        headers: {
            'crossDomain': true,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Content-Type': 'application/json',
            //'Content-Type': 'text/plain;charset=utf-8',
        },
    }).
        then((response) => {

            callback(response)
        }).catch(err => {
            console.log(err)
        })
}


const deleteProd = async (codArticolo, codiceLista, callback) => {
    console.log(["LOG AXIOS CALL"])
    console.log("idArticolo", codArticolo)
    console.log("codLista", codiceLista)
    const header = await createToken();
    axios.post(IP + '/deleteProdLista', { idArticolo: codArticolo, CodLista: codiceLista }, header).
        then((response) => {

            callback(response)
        }).catch(err => {
            console.log(err)
        })
}
const getProdLista = async (codLista, callback) => {
    console.log("[sono in getProdLista]")
    console.log("codiceLista", codLista)
    const header = await createToken();

    axios.post(IP + "/getProdLista", { var1: codLista }, header).then(res => {

        if (res.status == 200) {
            callback(res)
        }
        else {
            callback("FAILED")
        }
    }).catch(err => {
        console.log(err)
        callback("FAILED")
    })
}
const inProdListaRegalo = async (input1, input2, input3, input4,selectSize, callback) => {
    console.log("sono qui ")
    const header = await createToken();
    axios.post(IP + "/ProdinListaRegalo", { var1: input1, var2: input2, var3: input3, var4: input4,selectedSize:selectSize }, header)
        .then(res => {
            callback(res)
        })
}
const getLists = async (codiceLista,nomeLista) => {
    let headers ={
        headers:{
            'Content-Type': 'application/json'
        }
    }
    let response;
    if(codiceLista!==null && nomeLista===null){
        response = await axios.get(env.IP+api.API_SEARCH_LIST_FROM_ID+"?codice="+codiceLista,headers)
    }else if(nomeLista!=null && codiceLista==null) {
        response = await axios.get(env.IP+api.API_SEARCH_LIST_FROM_NAME+"?name="+nomeLista,headers)
    }

    //MOCK
    //await new Promise(resolve => setTimeout(resolve, 2000));
    //return null

    return response

}
const getList = async (codiceLista) => {

    let headers ={
        headers:{
            'Content-Type': 'application/json'
        }
    }

    let response = await axios.get(env.IP+api.API_GET_PRODUCT_LIST+"?codice="+codiceLista,headers)

    //await new Promise(resolve => setTimeout(resolve, 2000));
    //return null
    return response

}
const getAddressLista = async (idIndirizzo) => {


    const headers = await createToken();

    headers.headers['Content-Type']='application/json'

    let response = await axios.get(env.IP+api.API_GET_ADDRESS_FROM_LIST+"?idIndirizzo="+idIndirizzo,headers)

    return response

}
const updateProdLista = async (values, callback) => {
    const header = await createToken();
    axios.post(IP + "/updateProdLista",  values , header)
        .then(res => {
            callback(res)
        })
}
const sendEmails = async (values, callback) => {
    console.log("AXIOS CALL : ", values)
    const header = await createToken();
    axios.post(IP + "/lista-regalo/send-emails", { values }, header).
    then(res => {
        callback(res)
    })
}
export {
    getProdLista,
    updateProdLista,
    inProdListaRegalo,
    getArticoli,
    getCategorie,
    getSottoCategorie,
    whoiam,
    setInformation,
    getAllinformation,
    setInformationEmail,
    modifyInfo,
    deleteaddress,
    updateadresscall,
    addaddresscall,
    doListaRegalo,
    updateListaRegalo,
    checkListe,
    deleteCard,
    getfoto,
    deleteProd,
    checkout,
    getLists,
    getList,
    getAddressLista,
    sendEmails
}