import PropTypes from "prop-types";
import React from "react";
import { setActiveSort ,setActiveSort_all,setActiveSort_all_categorie} from "../../helpers/product";
import { multilanguage } from "redux-multilanguage";
const ShopCategories = ({ categories, getSortParams,strings }) => {
  return (
    <div className="sidebar-widget">
      <h4 style={{marginTop:"0.5cm"}}className="pro-sidebar-title">{strings['categorieneg']} </h4>
      <div className="sidebar-widget-list mt-30">
        {categories ? (
          <ul>
            <li>
              <div  className="sidebar-widget-list-left">
                <button
                  id="allcategories"
                  onClick={e => {
                    getSortParams("category", "");
                    setActiveSort_all_categorie(e);
                  }}
                >
                  <span className="checkmark" /> {strings['categorienegall']}
                </button>
              </div>
            </li>
            {categories.map((category, key) => {
              return (
                <li key={key}>
                  <div  className="sidebar-widget-list-left">
                    <button
                    id="categories"
                      onClick={e => {
                        getSortParams("category", category);
                        setActiveSort(e,"c");
                      }}
                    >
                      {" "}
                      <span className="checkmark" /> {strings[category]}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No categories found"
        )}
      </div>
    </div>
  );
};

ShopCategories.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
  strings:PropTypes.object
};

export default multilanguage(ShopCategories);
