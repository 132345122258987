import Product from "../../pages/shop-product/Product";

export const ADD_TO_CART = "ADD_TO_CART";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const DELETE_FROM_CART = "DELETE_FROM_CART";
export const DELETE_ALL_FROM_CART = "DELETE_ALL_FROM_CART";

//add to cart
export const addToCart = (
    item,
    addToast,
    quantityCount,
    selectedProductColor,
    selectedProductSize,
    UpdateBanner,
    fromLista,
    nomeLista,
    cartItems,
    codiceLista,
    idIndirizzoSpedizioneLista
) => {
  return dispatch => {

    //CONTROLLI SE AMMETTERE O MENO IL PRODOTTO AL CARRELLO
    let checkListaNascita = true;
    if(fromLista){
      //AGGIUNGO AL PRODOTTO IL TAG LISTANSCITA CON NOME
      item.listaNascita = true
      item.nomeLista=nomeLista
      item.codiceLista=codiceLista
      item.idIndirizzoSpedizioneLista=idIndirizzoSpedizioneLista
      //CONTROLLO SE C'E GIA UN PRODOTTO NEL CARRELLO
      if(cartItems.length>0){

        for(let index = 0; index < cartItems.length; index++){
          const cartItem = cartItems[index];
          if(cartItem.listaNascita != true){
            addToast("Il tuo carrello contiene già 1 o più articoli. Completa l'acquisto o svuota il carrello prima di aggiungere articoli della lista regalo.", { appearance: "error", autoDismiss: false });
            checkListaNascita = false;
            break;
          }else{
            if(cartItem.nomeLista!=nomeLista){
              addToast("Il tuo carrello contiene già 1 o più articoli di un'altra Lista Regalo. Completa l'acquisto o svuota il carrello prima di aggiungere articoli della lista regalo.", { appearance: "error", autoDismiss: false });
              checkListaNascita = false;
              break;
            }
          }
        }
      }
    }else {
      //NOT FROM LISTA

      //GET CARRELLO
      const storedValue = localStorage.getItem('redux_localstorage_simple');
      const cartData = JSON.parse(storedValue).cartData

      //CONTROLLO SE C'E GIA UN PRODOTTO DI UNA LISTA NASCITA NEL CARRELLO
      if (cartData.length > 0) {
        for (let index = 0; index < cartData.length; index++) {
          const cartItem = cartData[index];
          if (cartItem.listaNascita == true) {
            addToast("Il tuo carrello contiene già 1 o più articoli della Lista Regalo. Completa l'acquisto o svuota il carrello prima di aggiungere articoli della lista regalo.", {
              appearance: "error",
              autoDismiss: false
            });
            checkListaNascita = false;
            break;
          }
        }
      }
    }
    //FINE CONTROLLI SE AMMETTERE O MENO IL PRODOTTO AL CARRELLO


    if(checkListaNascita) {
      console.log("SUPERATO CHECK")
      if (addToast) {
        if (item.personalizzazione != "Non Supportata") {
          let json = JSON.parse(item.personalizzazione)
          let keys = Object.keys(json);
          for (let k = 0; k < keys.length; k++) {
            if (json[keys[k]] == '') {
              addToast("Personalizzazione non inserita! ", {appearance: "error", autoDismiss: true});
              return
            }
          }
        }
        addToast("Aggiunto al carrello", {appearance: "success", autoDismiss: true});
        if (UpdateBanner()) {
          addToast("Attenzione stai per acquistare un prodotto che non è disponibile immediatamente", {
            appearance: "warning",
            autoDismiss: false
          });
        }
      }
      dispatch({
        type: ADD_TO_CART,
        payload: {
          ...item,
          quantity: quantityCount,
          selectedProductColor: selectedProductColor
              ? selectedProductColor
              : item.selectedProductColor
                  ? item.selectedProductColor
                  : null,
          selectedProductSize: selectedProductSize
              ? selectedProductSize
              : item.selectedProductSize
                  ? item.selectedProductSize
                  : null

        }
      });
    }
  };
};
//decrease from cart
export const decreaseQuantity = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Articolo Decrementato dal carrello", {
        appearance: "warning",
        autoDismiss: true
      });
    }
    dispatch({ type: DECREASE_QUANTITY, payload: item });
  };
};
//delete from cart
export const deleteFromCart = (item, addToast) => {
  return dispatch => {
    if (addToast) {
      addToast("Rimosso dal carrello", { appearance: "error", autoDismiss: true });
    }
    dispatch({ type: DELETE_FROM_CART, payload: item });
  };
};
//delete all from cart
export const deleteAllFromCart = addToast => {
  return dispatch => {
    if (addToast) {
      addToast("Rimossi tutti dal carrello", {
        appearance: "error",
        autoDismiss: true
      });
    }
    dispatch({ type: DELETE_ALL_FROM_CART });
  };
};

// get stock of cart item
export const cartItemStock = (item, color, size) => {
  if (item.stock) {
    return item.stock;
  } else {
    return item.variation
        .filter(single => single.color === color)[0]
        .size.filter(single => single.name === size)[0].stock;
  }
};
