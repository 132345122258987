import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";

const ProvaListaCategorie = lazy(() => import("./pages/shop/ProvaListaCategorie"));
const ProvaListaSottocategorie = lazy(() => import("./pages/shop/ProvaListaSottocategorie"));
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));
const ShopListFullWidth = lazy(() => import("./pages/shop/ShopListFullWidth"));
const Product = lazy(() => import("./pages/shop-product/Product"));
const ListaBattesimo = lazy(() => import("./pages/ListeRegalo/ListaBattesimo"));
const About = lazy(() => import("./pages/other/About"));
const CentroAssistenza = lazy(() => import("./pages/other/CentroAssistenza"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const LoginRegister = lazy(() => import("./pages/other/LoginRegister"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Shopping = lazy(() => import("./pages/other/Shopping"));
const Basket = lazy(() => import("./pages/other/Basket"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const ListaNascitaSearch = lazy(()=>import("./pages/ListeRegalo/ListaNascitaSearch"));
const ListaNascitaViewer = lazy(()=>import("./pages/ListeRegalo/ListaNascitaViewer"));

const App = (props) => {
  useEffect(() => {

    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          it: require("./translations/italian.json"),
        }
      })
    );
  });

  return (
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>


                {/* NO BREADCRUM */}
                <Route exact path={process.env.PUBLIC_URL + "/"} component={HomeFashion}/>
                <Route path={process.env.PUBLIC_URL + "/shop"} component={ShopListFullWidth} />

                {/* CON BREADCRUM */}
                <Route path={process.env.PUBLIC_URL + "/product/:id"} render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route path={process.env.PUBLIC_URL + "/categoria/:id"} render={(routeProps) => (
                    <ProvaListaCategorie {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route path={process.env.PUBLIC_URL + "/sottocategoria/:id"} render={(routeProps) => (
                    <ProvaListaSottocategorie {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route path={process.env.PUBLIC_URL + "/basket/:id"} render={(routeProps) => (
                    <Basket {...routeProps} key={routeProps.match.params.id} />
                  )}
                />

                <Route exact path={process.env.PUBLIC_URL + "/lista-nascita/:codice"} render={(routeProps) => (
                    <ListaNascitaViewer {...routeProps} codice={routeProps.match.params.codice} />
                )}
                />

                <Route path={process.env.PUBLIC_URL + "/login-register"} component={LoginRegister}/>

                <Route path={process.env.PUBLIC_URL + "/forgot-password"} component={ForgotPassword}/>

                <Route path={process.env.PUBLIC_URL + "/my-account"} component={MyAccount}/>

                <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart}/>

                <Route path={process.env.PUBLIC_URL + "/checkout"} component={Checkout}/>

                <Route path={process.env.PUBLIC_URL + "/contact"} component={Contact}/>

                <Route path={process.env.PUBLIC_URL + "/shopping"} component={Shopping}/>

                <Route path={process.env.PUBLIC_URL + "/crea-lista-nascita"} component={ListaBattesimo}/>

                <Route path={process.env.PUBLIC_URL + "/about"} component={About}/>

                <Route path={process.env.PUBLIC_URL + "/centroassistenza"} component={CentroAssistenza}/>

                <Route path={process.env.PUBLIC_URL + "/wishlist"} component={Wishlist}/>

                <Route path={process.env.PUBLIC_URL + "/compare"} component={Compare}/>

                <Route exact path={process.env.PUBLIC_URL + "/lista-nascita"} component={ListaNascitaSearch}/>

                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>

      </BreadcrumbsProvider>
    </ToastProvider>
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};

export default connect()(multilanguage(App));
