import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const MobileNavMenu = ({ strings }) => {
  return (
      <nav className="offcanvas-navigation" id="offcanvas-navigation">
        <ul>
          <li className="menu-item-has-children">
            <Link to={process.env.PUBLIC_URL + "/"}>{strings["home"]}</Link>
          </li>

          <li className="menu-item-has-children">
            <Link to={process.env.PUBLIC_URL + "/shop"}>
              {strings["shop"]}
            </Link>
            <ul className="sub-menu">
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/A-spasso"}>
                  A spasso
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-trio-e-duo"}>
                      Trio e Duo
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-leggeri"}>
                      Passeggini Leggeri
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-passeggiare"}>
                      Accessori per Passeggiare
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-gemellari"}>
                      Passeggini Gemellari
                    </Link>
                  </li>

                  <li>
                    <Link
                        to={process.env.PUBLIC_URL + "/sottocategoria/marsupi-e-zaini"}
                    >
                      Marsupi, borse nascita e borse da viaggio
                    </Link>
                  </li>
                  <li>
                    <Link
                        to={process.env.PUBLIC_URL + "/sottocategoria/lettini-da-campeggio"}
                    >
                      Lettini da campeggio
                    </Link>
                  </li>



                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/Bagnetto"}>
                  Bagnetto
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/fasciatorio-e-bagnetti"}>
                      Fasciatoio e Bagnetti
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/vaschette-da-bagno"}>
                      Vaschette da Bagno
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/salute-e-benessere"}>
                      Salute e Benessere
                    </Link>
                  </li>
                  {/*
                <li>
                  <Link to={process.env.PUBLIC_URL + "/sottocategoria/igiene"}>
                    Igiene
                  </Link>
                </li>
                */}
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/arredamento"}>
                  Arredamento
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Culle-per-neonati"}>
                      Culle per neonati
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/lettini"}>
                      Lettini
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/armadietti"}>
                      Armadietti
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/complementi-d-arredo"}>
                      Complementi D'arredo
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-camerette"}>
                      Accessori Camerette
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/lettini-neonati"}>
                      Lettini per neonati
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/cassettiere"}>
                      Cassettiere
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/materassi-e-cuscini"}>
                      Materassi e Cuscini
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-culla"}>
                      Accessori per la culla
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/fiocchi-nascita"}>
                      Fiocchi di Nascita
                    </Link>
                  </li>

                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/sicurezza"}>
                  Sicurezza
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/baby-monitor"}>
                      Baby Monitor
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/cancelletti"}>
                      Cancelletti
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/zanzariere"}>
                      Zanzariere
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/barriere-da-letto"}>
                      Barriere Da Letto
                    </Link>
                  </li>
                  {/*
                <li>
                  <Link to={process.env.PUBLIC_URL + "/sottocategoria/paraspigoli"}>
                    Paraspigoli
                  </Link>
                </li>
                */}
                  <li>

                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/seggioliniauto"}>
                      Seggiolini Auto
                    </Link>
                  </li>

                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/gioco-e-relax"}>
                  Gioco e Relax
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/giocattoli"}>
                      Giocattoli
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/box-e-recinti"}>
                      Box e Recinti
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/girelli"}>
                      Girelli
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/biciclette"}>
                      Biciclette
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/altalene-e-dondoli"}>
                      Altalene e Dondoli
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/palestrine"}>
                      Palestrine
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/straiette"}>
                      Sdraiette
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/offerte"}>
                  Offerte
                </Link>

              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/abbigliamento"}>
                  Abbigliamento
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Asciugamani"}>
                      Asciugamani
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Tutine-Body"}>
                      Tutine e piccoli Body
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Copertine-Carrozzine"}>
                      Copertine e Lenzuola per Carrozzine
                    </Link>
                  </li>



                </ul>
              </li>
              <li className="menu-item-has-children">
                <Link to={process.env.PUBLIC_URL + "/categoria/pappa"}>
                  Pappa
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/allattamento"}>
                      Allattamento
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/seggiolini-pappa"}>
                      Seggiolini Pappa
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/sediolini-da-tavolo"}>
                      Sediolini Da Tavolo
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/alzasedia"}>
                      Alzasedia
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-sediolini"}>
                      Accessori Per Sediolini
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-la-pappa"}>
                      Accessori Per La Pappa
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/alimentazione"}>
                      Alimentanzione
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-la-dentizione"}>
                      Accessori Per La Dentizione
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <ul>
            <li>
              <Link to={process.env.PUBLIC_URL + "/lista-nascita"}>
                LISTA NASCITA
              </Link>
            </li>
          </ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/about"}>
              Su di Noi
            </Link>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/contact"}>
              {strings["contact_us"]}
            </Link>
          </li>

        </ul>

      </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

export default multilanguage(MobileNavMenu);
