import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import NavMenuCategorie from "../../components/header/NavMenuCategorie";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import MobileFilter from "../../components/header/MobileFilter";
import HeaderTop from "../../components/header/HeaderTop";
//Qua ho cambiato il logo
const HeaderOne = ({
  layout,
  top,
  borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
  notstick
}) => {
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
  const [resize, setresize] = useState(null);

  const updateSize = () => {
    
      if (window.innerWidth <= 1023 || window.innerHeight <= 481) {

        setresize(true)

      }
      else {
        setresize(false)
      }

    }
    useEffect(() => {
    if(notstick!="true"){
      if (window.innerWidth <= 1023 || window.innerHeight <= 136) {
        setresize(true)
      }
      else {
        setresize(false)
      }
      
      window.addEventListener('resize', updateSize);
      const header = document.querySelector(".sticky-bar");
      setHeaderTop(header.offsetTop);
      window.addEventListener("scroll", handleScroll);
    }
    else{
      if (window.innerWidth <= 1023 || window.innerHeight <= 136) {
        setresize(true)
      }
      else {
        setresize(false)
      }
      
      window.addEventListener('resize', updateSize);
    }
    return () => {
      if(notstick!="true"){
      window.removeEventListener("scroll", handleScroll);
      }
      
    };
  }, []);

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <header
      className={`header-area clearfix ${headerBgClass ? headerBgClass : ""} ${headerPositionClass ? headerPositionClass : ""
        }`}
    >
      <div
        className={`${headerPaddingClass ? headerPaddingClass : ""} ${top === "visible" ? "d-none d-lg-block" : "d-none"
          } header-top-area ${borderStyle === "fluid-border" ? "border-none" : ""
          }`}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>
          <HeaderTop borderStyle={borderStyle} />
        </div>
      </div>

      <div
        className={` ${headerPaddingClass ? headerPaddingClass : ""} sticky-bar header-res-padding clearfix `}
      >
        <div className={layout === "container-fluid" ? layout : "container"}>

          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4" style={{paddingRight:"0px",paddingLeft: "0px"}}>
               
              {!resize ?
                <Logo style={{ marginTop: "0" }} imageUrl="https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/prova-svg.svg?alt=media&token=4c21d1b8-c1f9-4a12-bdd5-1c2a8ad44223" logoClass="logo" />
                :
                <Logo style={{ marginTop: "0" }} imageUrl="https://firebasestorage.googleapis.com/v0/b/website-arredobimbo.appspot.com/o/prova-svg.svg?alt=media&token=4c21d1b8-c1f9-4a12-bdd5-1c2a8ad44223" logoClass="logo" />
              }




            </div>
            <div className="col-xl-10 col-lg-10 col-md-6 col-8">
              {/* Nav menu 
              <NavMenu />
              */}
              <div className="row" style={{marginTop: resize===true?"2.5vh" : "0vh"}}>
                <div className="col-xl-10 col-lg-10 col-md-6 col-8 ">
                  {/* Nav menu */}
                  {!resize ?
                    <NavMenu />
                    :
                    <div></div>
                  }
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-4 ">
                  {/* Icon group */}
                  <IconGroup />
                </div>
              </div>

              <div className="row">
                <div className="col-xl-10 col-lg-10 col-md-6 col-8 ">
                  {/* Nav menu */}
                  {!resize ?
                    <NavMenuCategorie />
                    :
                    <div></div>
                  }
                  
                </div>
                <div className="col-xl-2 col-lg-2 col-md-6 col-4 " >
                  {/* Icon group */}

                </div>
              </div>
            </div>

          </div>



        </div>

        <MobileMenu />
      
      </div>

    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string
};

export default HeaderOne;