import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/A-spasso"}>
              {" "}
              {strings["A-spasso"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-leggeri"}>
                      {strings["A-spasso2"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-passeggiare"}>
                      {strings["A-spasso3"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-gemellari"}>
                      {strings["A-spasso4"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/passeggini-trio-e-duo"}>
                      {strings["A-spasso5"]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/sottocategoria/marsupi-e-zaini"}
                    >
                      {strings["A-spasso6"]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/sottocategoria/lettini-da-campeggio"}
                    >
                      {strings["A-spasso7"]}
                    </Link>
                  </li>
                </ul>
              </li>
              
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/categoria/A-spasso"}>
                      <img
                        style={{width:"200%"}}
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/categoria_a_spasso.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/Bagnetto"}>
              {" "}
              {strings["bagnetto"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/fasciatoio-e-bagnetti"}>
                      {strings["bagnetto2"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/vaschette-da-bagno"}>
                      {strings["bagnetto3"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/salute-e-benessere"}>
                      {strings["bagnetto4"]}
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/igiene"}>
                      {strings["bagnetto5"]}
                    </Link>
                  </li>
                  */}
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/categoria/Bagnetto"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/bagnetto 2.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/arredamento"}>
              {" "}
              {strings["arredamento"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Culle-per-neonati"}>
                      {strings["arredamento1"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/lettini"}>
                      {strings["arredamento2"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/armadietti"}>
                      {strings["arredamento3"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/complementi-d-arredo"}>
                      {strings["arredamento4"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-camerette"}>
                      {strings["arredamento5"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/lettini-neonati"}>
                      {strings["arredamento6"]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/sottocategoria/cassettiere"}
                    >
                      {strings["arredamento7"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/materassi-e-cuscini"}>
                      {strings["arredamento8"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-culla"}>
                      {strings["arredamento9"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/fiocchi-nascita"}>
                      {strings["arredamento10"]}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/categoria/arredamento"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/arredamento2.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/sicurezza"}>
              {" "}
              {strings["sicurezza"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/baby-monitor"}>
                      {strings["sicurezza1"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/cancelletti"}>
                      {strings["sicurezza2"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/zanzariere"}>
                      {strings["sicurezza3"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/barriere-da-letto"}>
                      {strings["sicurezza4"]}
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/paraspigoli"}>
                      {strings["sicurezza5"]}
                    </Link>
                  </li>
                */}
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/sottocategoria/seggioliniauto"} >
                      {strings["sicurezza7"]}
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
              
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/categoria/sicurezza"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/sicurezza.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/gioco-e-relax"}>
              {" "}
              {strings["gioco"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                 
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/giocattoli"}>
                      {strings["gioco1"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/box-e-recinti"}>
                      {strings["gioco2"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/girelli"}>
                      {strings["gioco3"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/biciclette"}>
                      {strings["gioco4"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/altalene-e-dondoli"}>
                      {strings["gioco5"]}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={process.env.PUBLIC_URL + "/sottocategoria/palestrine"}>
                      {strings["gioco6"]}
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/sdraiette"}>
                      {strings["gioco7"]}
                    </Link>
                  </li>
                </ul>
              </li>
         
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/categoria/gioco-e-relax"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/giocobimbo.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          
          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/offerte"}>
              {" "}
              {strings["offerte"]}
            </Link>
          </li>

          
        

          
          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/abbigliamento"}>
              {" "}
              {strings["abbigliamento"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu" style={{width:"auto"}}>
            <li>
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Asciugamani"}>
                      {strings["abbigliamento3"]}
                    </Link>
                  </li>
                  
                </ul>
              </li>
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Tutine-Body"}>
                      {strings["abbigliamento4"]}
                    </Link>
                  </li>
                  
                </ul>
              </li>
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/sottocategoria/Copertine-Carrozzine"}>
                      {strings["abbigliamento5"]}
                    </Link>
                  </li>
                  
                </ul>
              </li>
              <li>
                <ul>
                  <li className="mega-menu-img">
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/img/banner/banner-50.jpg"
                        }
                        alt=""
                      />
                    </Link>
                  </li>
                </ul>
              </li>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/categoria/pappa"}>
              {strings["pappa"]}
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="submenu">
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/allattamento"}>
                  {strings["pappa1"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/seggiolini-pappa"}>
                  {strings["pappa2"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/sediolini-da-tavolo"}>
                  {strings["pappa3"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/alazasedia"}>
                  {strings["pappa4"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-sediolini"}>
                  {strings["pappa5"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-la-pappa"}>
                  {strings["pappa6"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/alimentazione"}>
                  {strings["pappa7"]}
                </Link>
              </li>
              <li>
                <Link to={process.env.PUBLIC_URL + "/sottocategoria/accessori-per-la-dentizione"}>
                  {strings["pappa8"]}
                </Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

export default multilanguage(NavMenu);
