const locale = {
    IP: 'http://localhost:8080'
};

const sviluppo = {
    IP: 'https://server.maffle.it:8443/arredobimbo/sviluppo'
};

const collaudo = {
    IP: 'https://server.maffle.it:8443/arredobimbo/collaudo'
};

const produzione = {
    IP: 'https://arredobimbo.it:8443'
};

const getEnv = () => {
    switch (process.env.REACT_APP_ENV) {
        case 'sviluppo':
            return sviluppo
        case 'collaudo':
            return collaudo
        case 'produzione':
            return produzione
        default:
            return locale
    }
}

export const env = getEnv()
