import PropTypes from "prop-types";
import React from "react";
import { setActiveSort,setActiveSort_all_colors } from "../../helpers/product";
import { multilanguage } from "redux-multilanguage";
const ShopColor = ({ colors, getSortParams,strings }) => {
  return (
    <div className="sidebar-widget mt-50">
      <h4 className="pro-sidebar-title">{strings['colori']}</h4>
      <div className="sidebar-widget-list mt-20">
        {colors ? (
          <ul>
            <li>
              <div className="sidebar-widget-list-left">
                <button
                id="allcolors"
                  onClick={e => {
                    getSortParams("color", "");
                    setActiveSort_all_colors(e);
                  }}
                >
                  <span className="checkmark" /> {strings['tutticolori']}{" "}
                </button>
              </div>
            </li>
            {colors.map((color, key) => {
              return (
                <li key={key}>
                  <div className="sidebar-widget-list-left">
                    <button
                    id="colors"
                      onClick={e => {
                        getSortParams("color", color);
                        setActiveSort(e,'col');
                      }}
                    >
                    
                      <span className="checkmark" /> {color}{" "}
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
        ) : (
          "No colors found"
        )}
      </div>
    </div>
  );
};

ShopColor.propTypes = {
  colors: PropTypes.array,
  getSortParams: PropTypes.func,
  strings: PropTypes.object
};

export default multilanguage(ShopColor);
