import firebase from 'firebase';

var firebaseConfig = {
    apiKey: "AIzaSyCzxm-bnNPhBVA5rGUYXADNx1j4_v5vPr8",
    authDomain: "website-arredobimbo.firebaseapp.com",
    projectId: "website-arredobimbo",
    storageBucket: "website-arredobimbo.appspot.com",
    messagingSenderId: "463706240967",
    appId: "1:463706240967:web:ffeac3c9b4f4c229468be2",
    measurementId: "G-F2F77BER6X"
  };

  try{
      firebase.initializeApp(firebaseConfig)
  }catch(err){
  if (!/already exists/.test(err.message)) {
    console.error('Firebase initialization error', err.stack);
  }
}
const fire = firebase;
export default fire;