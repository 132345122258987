import PropTypes from "prop-types";
import React, { Fragment , useState, useEffect} from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { getDiscountPrice } from "../../../helpers/product";
import { multilanguage } from "redux-multilanguage";
const MenuCart = ({ strings,cartData, currency, deleteFromCart }) => {
  let cartTotalPrice = 0;
  const { addToast } = useToasts();
  useEffect(() => {
  }, []);
  return (
      <div className="shopping-cart-content">
        {cartData && cartData.length > 0 ? (
            <Fragment>
              <ul>
                {cartData.map((single, key) => {
                  const discountedPrice = getDiscountPrice(
                      single.price,
                      single.discount
                  );
                  const finalProductPrice = (
                      single.price * currency.currencyRate
                  ).toFixed(2);
                  const finalDiscountedPrice = (
                      discountedPrice * currency.currencyRate
                  ).toFixed(2);

                  discountedPrice != null
                      ? (cartTotalPrice += finalDiscountedPrice * single.quantity)
                      : (cartTotalPrice += finalProductPrice * single.quantity);

                  return (
                      <li className="single-shopping-cart" key={key}>
                        <div className="shopping-cart-img">
                          <Link to={process.env.PUBLIC_URL + "/product/" + single.id}>
                            <img
                                alt=""
                                src={process.env.PUBLIC_URL + single.variation[single.IndexColor[single.selectedProductColor]].image}
                                className="img-fluid"
                            />
                          </Link>
                        </div>
                        <div className="shopping-cart-title">
                          <h4>
                            <Link
                                to={process.env.PUBLIC_URL + "/product/" + single.id}
                            >
                              {" "}
                              {single.name}{" "}
                            </Link>
                          </h4>
                          <h6>{strings['qta']}: {single.quantity}</h6>
                          <span>
                            {discountedPrice !== null
                                ? currency.currencySymbol + finalDiscountedPrice
                                : currency.currencySymbol + finalProductPrice
                            }
                          </span>
                          {single.listaNascita && <h6>Lista: <span>{single.nomeLista}</span></h6>}
                          {single.selectedProductColor &&
                          single.selectedProductSize ? (
                              <div className="cart-item-variation">
                                <span>{strings['colore']}: {single.selectedProductColor}</span>
                                {single.selectedProductSize!="tagliau" && single.selectedProductSize!="TAGLIA_UNICA" &&
                                    <span>Taglia: {single.selectedProductSize}</span>
                                }
                              </div>
                          ) : (
                              ""
                          )}


                        </div>
                        <div className="shopping-cart-delete">
                          <button onClick={() => deleteFromCart(single, addToast)}>
                            <i className="fa fa-times-circle" />
                          </button>
                        </div>
                      </li>
                  );
                })}
              </ul>
              <div className="shopping-cart-total">
                <h4>
                  {strings['TotaleSpeso']} :{" "}
                  <span className="shop-total">
                {currency.currencySymbol + cartTotalPrice.toFixed(2)}
              </span>
                </h4>
              </div>
              <div className="shopping-cart-btn btn-hover text-center">
                <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
                  {strings['viewcart']}
                </Link>
                <Link
                    className="default-btn"
                    to={process.env.PUBLIC_URL + "/checkout"}
                >
                  {strings['checkout']}
                </Link>
              </div>
            </Fragment>
        ) : (
            <p className="text-center">{strings['noitemcart']}</p>
        )}
      </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  deleteFromCart: PropTypes.func,
  strings: PropTypes.object
};

export default multilanguage (MenuCart);
