import React from "react";

const MobileWidgets = () => {
  return (
    <div className="offcanvas-widget-area">
      <div className="off-canvas-contact-widget">
        <div className="header-contact-info">
          <ul className="header-contact-info__list">
            <li>
              <i className="fa fa-phone"></i>{" "}
              <a href="tel://3347259681">3347259681 </a>
            </li>
            <li>
              <i className="fa fa-envelope"></i>{" "}
              <a >arredobimbo@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
      {/*Off Canvas Widget Social Start*/}
      <div className="off-canvas-widget-social">
 
        <a href="https://www.instagram.com/arredo_bimbo_savino_/?hl=it" title="Instagram">
          <i className="fa fa-instagram"></i>
        </a>
        <a href="//www.facebook.com/arredobimbo.savino" title="Facebook">
          <i className="fa fa-facebook"></i>
        </a>

      </div>
      {/*Off Canvas Widget Social End*/}
    </div>
  );
};

export default MobileWidgets;
