import PropTypes, { checkPropTypes } from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tooltip } from 'reactstrap';
import { getProductCartQuantity } from "../../helpers/product";
import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions";
import Rating from "./sub-components/ProductRating";
import { multilanguage } from "redux-multilanguage";
import fire from "../../firebase/firebase";
import Swal from 'sweetalert2';
import useForceUpdate from 'use-force-update';
import { checkListe, inProdListaRegalo, getProdLista } from '../../axios/axiosCall';
import { email, configWhatsApp } from "../../configs/AppConfig";
import { Label, FormGroup } from "reactstrap";
import { Width } from "devextreme-react/chart";

//import ordinalsCardinals from 'italian-ordinals-cardinals'
var ordinalsCardinals = require('italian-ordinals-cardinals');
const ProductDescriptionInfo = ({
                                  product,
                                  discountedPrice,
                                  currency,
                                  finalDiscountedPrice,
                                  finalProductPrice,
                                  cartItems,
                                  wishlistItem,
                                  compareItem,
                                  addToast,
                                  change,
                                  setUpdateBannerAfter,
                                  addToCart,
                                  addToWishlist,
                                  addToCompare,
                                  strings,
                                  updateFather
                                }) => {
  const forceUpdate = useForceUpdate();

  const [selectedProductColor, setSelectedProductColor] = useState(
      product.variation ? product.variation[0].color : ""
  );
  const [selectedProductSize, setSelectedProductSize] = useState(
      product.variation ? product.variation[0].size[0].name : ""
  );
  const [productStock, setProductStock] = useState(
      product.variation ? product.variation[0].size[0].stock : product.stock
  );
  const [quantityCount, setQuantityCount] = useState(1);

  const [tooltipOpen, setTooltipOpen] = useState(false);



  const [tooltipOpen_color, setTooltipOpen_color] = useState(false);
  const [marca, setMarca] = useState(false)
  const [logged, setlogged] = useState(null);
  const [liste, setListe] = useState([])
  const [resize, setresize] = useState(null);
  const [numberOfPersonalization, setNumberOfPersonalization] = useState([false])
  const [personalizza, setPersonalizzaViewCheck] = useState([false])
  const [persnalizzazioniJSON, setpersonalizzazioniJSON] = useState(
      cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==selectedProductSize).length>0
          ?
          cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==selectedProductSize)[0].personalizzazione
          :
          "{}")

  const [offset, setOffset] = useState(
      cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==selectedProductSize).length>0
          ?
          cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==selectedProductSize)[0].quantity
          :
          0
  )


  //stati tolti per la lista
  const [seleziona, setSeleziona] = useState([]);
  const [info, setInfo] = useState()
  const [coloriInfo, setColoriInfo] = useState([])
  const [BannerAttezione,setBannerAttenzione] = useState(false);

  const updateSize = () => {
    if (window.innerWidth <= 1023 || window.innerHeight <= 481) {
      setresize(true)
    }
    else {
      setresize(false)
    }
  }

  const UpdateBanner = () =>{

    console.log("UpdateBanner "+product.name)
    let cleanOffset = offset+quantityCount
    setQuantityCount(1);
    setNumberOfPersonalization([false])
    setOffset(cleanOffset)
    return product.variation.find(x=>x.color===selectedProductColor).size.find(y=>y.name===selectedProductSize).prenotazione
  }


  const productCartQty = getProductCartQuantity(
      cartItems,
      product,
      selectedProductColor,
      selectedProductSize
  );

  const changeInitPersSize = (size) =>{
    const findCartItems=cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==size)
    console.log("CHECK findCartItemsSize",findCartItems)
    if(findCartItems.length>0){
      console.log("CHECK findCartItemsSize",findCartItems[0].personalizzazione)
      setpersonalizzazioniJSON(findCartItems[0].personalizzazione)
      setOffset(JSON.parse(findCartItems[0].quantity))


    }
    else {
      setpersonalizzazioniJSON("{}")
      setOffset(0)
    }
  }

  const changeInitPersColor = (color) =>{
    console.log("CHECK CHANGE COLOR ",color)
    const findCartItems=cartItems.filter(x=>x.id==product.id && x.selectedProductColor==color && x.selectedProductSize==selectedProductSize)
    console.log("CHECK findCartItemsColor",findCartItems)
    if(findCartItems.length>0){
      console.log("CHECK findCartItemsColor",findCartItems[0].personalizzazione)
      setpersonalizzazioniJSON(findCartItems[0].personalizzazione)
      setOffset(JSON.parse(findCartItems[0].quantity))
    }
    else {
      setpersonalizzazioniJSON("{}")
      setOffset(0)
    }
  }
  const change_pers = (key,offset) => {
    //console.log("CHECK CHANGE PERS",key+offset)
    let json = JSON.parse(persnalizzazioniJSON)
    json[(key+offset)] = document.getElementsByClassName(key)[0].value
    setpersonalizzazioniJSON(JSON.stringify(json))
    console.log("CHANGE JSON",json)
    product.personalizzazione = JSON.stringify(json)

  }


  const changeImage = (color) => {
    console.log("ho cambiato colore", color)

  }


  fire.auth().onAuthStateChanged((user) => {
    if (user) {

      setlogged(true)


    } else {

      setlogged(false)
    }
  });





  const setQuantityPlus = () => {
    if (quantityCount < productStock - productCartQty) {
      numberOfPersonalization.push(false)
    }

    setQuantityCount(
        quantityCount < productStock - productCartQty
            ? quantityCount + 1
            : quantityCount
    )
  }

  const setQuantityMinus = () => {
    if (quantityCount > 1) {
      numberOfPersonalization.splice(numberOfPersonalization.length - 1, 1);
    }
    setQuantityCount(quantityCount > 1 ? quantityCount - 1 : 1)
  }

  const Infolista = value => {
    setInfo(value)
  }
  const setPersonalizzazioneViewTrue = (key,offset) => {

    let vett = numberOfPersonalization
    vett[key] = true
    setNumberOfPersonalization(vett)

    let json = JSON.parse(persnalizzazioniJSON)
    json[(key+offset)] = ""
    setpersonalizzazioniJSON(JSON.stringify(json))
    product.personalizzazione = JSON.stringify(json)
    console.log("CHECK ",product.personalizzazione)
    forceUpdate();

  }
  const setPersonalizzazioneViewFalse = (key,offset) => {
    let vett = numberOfPersonalization
    vett[key] = false
    setNumberOfPersonalization(vett)
    console.log("DELETE ",key+offset)
    let json = JSON.parse(persnalizzazioniJSON)
    delete json[(key+offset)]
    console.log("AFTER DELETE",json)
    setpersonalizzazioniJSON(JSON.stringify(json))
    product.personalizzazione = JSON.stringify(json)
    console.log("CHECK ",product.personalizzazione)
    forceUpdate();

  }
  const handleAddrTypeChange = (e) => {
    setSeleziona(e.target.value)
    if (e.target.value == "0") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Non hai selezionato nessuna lista',
      })
    }
    else {


      let cane = []
      let gatto = []
      getProdLista(e.target.value, response => {

        console.log("response", response)


        let cane = response.data
        cane.map((postData) => {
              gatto.push(postData.idArticolo)
              console.log("id", gatto)
            }
        )



        if (gatto.includes(product.id)) {
          console.log("ammooooo")
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hai già inserito questo articolo in questa lista',
            footer: '<a href="/shop">Inserisci un nuovo articolo</a>'
          })
        }
        else {
          Swal.fire({
            title: 'Sto aggiungendo questo prodotto alla lista selezionata ',
            text: "Colore:" + selectedProductColor + " e quantità: " + quantityCount,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok, confermo la selezione!'
          }).then((result) => {
            if (result.isConfirmed) {

              inProdListaRegalo(e.target.value, product.id, selectedProductColor, quantityCount, selectedProductSize,response => {
                console.log("ale", response)
                if (response.status == 200) {
                  addToast("Prodotto aggiunto alla lista selezionata", {
                    appearance: "success",
                    autoDismiss: true
                  });
                }
                else {
                  addToast("Il prodotto non è stato aggiunto", {
                    appearance: "error",
                    autoDismiss: false
                  });
                }



              })

            }
            else {
              setSeleziona([])
            }
          })
        }


        /*
  
        if (response.data == 0) {
          addToast("Non è stato inserito nessun prodotto in questa lista ", {
            appearance: "warning",
            autoDismiss: true
          });
        }
        else {
          setprodLista(response.data)
        }
        */
      });
    }




  }
  useEffect(() => {
    let vect = []
    for (let i = 0; i < product.variation.length; i++) {
      vect.push(false)
    }
    setColoriInfo(vect)

    if (window.innerWidth <= 1023 || window.innerHeight <= 136) {
      setresize(true)
    }
    else {
      setresize(false)
    }
    window.addEventListener('resize', updateSize);


    if (product.tag == "Chicco edition" || product.tag == "Cam - linea solo per te") {
      setMarca(true)
    } else {
      setMarca(false)
    }

    var user = fire.auth().currentUser;
    if (user) {
      checkListe(response => {

        if (response.length == 0) {
        }
        else {

          setListe(response)
        }
      })
    }
  }, [logged]);

  useEffect(() => {
    let findItem = cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor && x.selectedProductSize==selectedProductSize).length>0
    console.log("CHECK FIND ITEM "+product.name,findItem)
    if(findItem===false){

      setQuantityCount(1);
      setNumberOfPersonalization([false])
      setOffset(0)
    }
  }, [cartItems]);

  useEffect(() => {
    console.log("CHECK USE_EFFECT")

    if(cartItems.filter(x=>x.id==product.id && x.selectedProductColor==selectedProductColor
        && x.selectedProductSize==selectedProductSize).length>0) {

      product.personalizzazione = cartItems.filter(x => x.id == product.id && x.selectedProductColor == selectedProductColor && x.selectedProductSize == selectedProductSize)[0].personalizzazione
    }
    else {
      product.personalizzazione = "{}"
    }
    updateFather(selectedProductColor,selectedProductSize,quantityCount)
  }, [selectedProductColor,selectedProductSize]);

  useEffect(()=>{
    updateFather(selectedProductColor,selectedProductSize,quantityCount)
  },[])

  useEffect(()=>{
    updateFather(selectedProductColor,selectedProductSize,quantityCount)
  },[quantityCount])

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const setColorInfoToggle = (key) => {
    //e.preventDefault()
    //console.log("test-ale",key)
    //coloriInfo[key]=!coloriInfo[key]

  }
  const ColorInfoSetting = (key, valore, e) => {
    e.preventDefault()
    console.log("test-ale", key)
    console.log("testinfo", coloriInfo)
    let vect = []
    for (let j = 0; j < coloriInfo.length; j++) {
      if (j == key) {
        vect.push(valore)
      }
      else {
        vect.push(false)
      }

    }
    setColoriInfo(vect)
  }
  return (
      <div className="product-details-content ml-70">

        <h2>{product.name}</h2>
        {product.variation.find(x=>x.color===selectedProductColor).size.find(y=>y.name===selectedProductSize).prenotazione &&
            <div className="banner" >ATTENZIONE CONSEGNA IN {product.variation.find(x=>x.color===selectedProductColor).size.find(y=>y.name===selectedProductSize).giorni} GIORNI </div>
        }
        <div className="product-details-price">
          {discountedPrice !== null ? (
              <Fragment>
                <span>{!marca && currency.currencySymbol + finalDiscountedPrice}</span>{" "}
                <span className="old">
              {!marca && currency.currencySymbol + finalProductPrice}
            </span>
              </Fragment>
          ) : (
              <span>{!marca && currency.currencySymbol + finalProductPrice} </span>
          )}
        </div>
        {product.rating && product.rating > 0 ? (
            <div className="pro-details-rating-wrap">
              <div className="pro-details-rating">
                <Rating ratingValue={product.rating} />
              </div>
            </div>
        ) : (
            ""
        )}
        <div className="pro-details-list">
          <p>{product.shortDescription}</p>
          {marca && <a href={configWhatsApp}   >
            <img src="https://www.lacasadelbebe.it/whatsapp_icon.png"></img>
          </a>}
          {marca && <a href={"mailto:arredobimbo@gmail.com?subject=Informazioni%20sul%20prodotto " + product.name + ""}>

            Scrivi una mail per maggiori info sul prodotto</a>}


          {/*qui devo mettere il segno di wwhatsapp*/}

        </div>
        <div>
          {product.variation ? (
              <div>
                <div className="pro-details-size-color">
                  <div className="pro-details-color-wrap">
                    <span>{strings['colore']}</span>
                    <div className="pro-details-color-content">
                      {product.variation.map((single, key) => {
                        return (
                            <label
                                className={`pro-details-color-content--single ${single.color}`}
                                key={key}
                                id={single.color}
                                style={{left:"5px"}}
                                onMouseOver={(e) => ColorInfoSetting(key,true,e)}
                                onMouseLeave={(e)=>ColorInfoSetting(key,false,e)}

                            >
                              <input
                                  //id={single.color}

                                  type="radio"
                                  value={single.color}
                                  name="product-color"
                                  checked={
                                    single.color === selectedProductColor ? "checked" : ""
                                  }

                                  //onMouseOver={ColorInfoSetting(key)}
                                  //onMouseOver={(e)=>ColorInfoSetting(key)}
                                  //onMouseLeave={(e)=>ColorInfoSetting(key)}
                                  //onMouseOver={(e) => console.log("onmouse")}
                                  onChange={() => {
                                    setpersonalizzazioniJSON({})
                                    changeInitPersColor(single.color)
                                    setSelectedProductColor(single.color);
                                    setSelectedProductSize(single.size[0].name);
                                    setProductStock(single.size[0].stock);
                                    setQuantityCount(1);
                                    change(single.color);
                                    //ColorInfoSetting(key,true)
                                    setNumberOfPersonalization([false]);
                                  }}
                              />
                              <span className="checkmark"></span>

                              {coloriInfo[key] &&
                                  <Tooltip class="shopping-cart-content active" syle={{ backgroudColor: "black" }} isOpen={true} placement="top" target={single.color} toggle={setColorInfoToggle(key)} >

                                    <div style={{ width: "80px", height: "80px", backgroundColor: window.getComputedStyle(document.getElementById(single.color)).getPropertyValue('background-color') }}>

                                    </div>
                                    {single.color}
                                  </Tooltip>
                              }

                            </label>


                        );

                      })}

                    </div>

                  </div>

                  {selectedProductSize != "tagliau" && selectedProductSize != "TAGLIA_UNICA" &&
                      <div className="pro-details-size">
                        <span>Size</span>
                        <div className="pro-details-size-content">
                          {product.variation &&
                              product.variation.map(single => {
                                return single.color === selectedProductColor
                                    ? single.size.map((singleSize, key) => {
                                      return (

                                          <label
                                              className={`pro-details-size-content--single`}
                                              key={key}
                                          >
                                            <input
                                                type="radio"
                                                value={singleSize.name}
                                                checked={
                                                  singleSize.name === selectedProductSize
                                                      ? "checked"
                                                      : ""
                                                }
                                                onChange={() => {
                                                  setpersonalizzazioniJSON({})
                                                  setSelectedProductSize(singleSize.name);
                                                  changeInitPersSize(singleSize.name)
                                                  setProductStock(singleSize.stock);
                                                  setQuantityCount(1);
                                                  setNumberOfPersonalization([false]);
                                                }}
                                            />
                                            <span className="size-name">{singleSize.name}</span>
                                          </label>
                                      );
                                    })
                                    : "";
                              })}
                        </div>
                      </div>
                  }
                </div>


              </div>
          ) : (
              ""
          )}
        </div>

        <div>
          {numberOfPersonalization.map((single, key) => (

              <div>
                {!marca && product.isPersonalized &&
                    <div className="pro-details-size-color" style={{ display: "initial", marginTop: "40px" }}>
                      <div className="pro-details-color-wrap" style={{ marginTop: "25px" }}>
                        <div className="row" style={{alignItems:"center",flexWrap: "nowrap"}}>
                          <div className=" col-lg-1" style={{left: "5px"}}>
                            <div className="sidebar-widget">
                              <div className="sidebar-widget-list " >
                                <ul >
                                  <li>
                                    <FormGroup check style={{ paddingLeft: "0" }}>
                                      <Label check >

                                        {!single ?
                                            <div className="sidebar-widget-list-left">
                                              <button
                                                  id={product.id+"-c1"}
                                                  onClick={e => { setPersonalizzazioneViewTrue(key,offset) }}>
                                                <span className="checkmark" />
                                              </button>
                                            </div>
                                            :
                                            <div className="sidebar-widget-list-left">
                                              <button
                                                  id={product.id+"-c1"}
                                                  class="active"
                                                  onClick={e => { setPersonalizzazioneViewFalse(key,offset) }}>
                                                <span className="checkmark" />
                                              </button>
                                            </div>
                                        }
                                      </Label>
                                    </FormGroup>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-11" style={{ top: "0.69vh" }}>
                            <span>Personalizza il {ordinalsCardinals.getOrdinal(key+offset + 1)} prodotto al costo di {product.costopersonalizzazione}€</span>
                          </div>
                        </div>

                        {single &&
                            <div className='contact-form' style={{ padding: "0px 0px 0px 0px", backgroundColor: "white" }}>
                              <form className="contact-form-style" >

                                <input style={{ marginBottom: "0px" }} required id="personalizzazione" className={key} type="text" onChange={() => change_pers(key,offset)}></input>

                              </form>

                              <Tooltip syle={{ backgroudColor: "#343538" }} isOpen={tooltipOpen} placement="top" target="personalizzazione" toggle={toggle} >
                                Inserisci qui la tua personalizzazione!! {product.personalizzazione_descrizione}
                              </Tooltip>
                            </div>
                        }
                      </div>
                    </div>
                }
              </div>
          ))}
        </div>
        {
          product.affiliateLink ? (
              <div className="pro-details-quality">
                <div className="pro-details-cart btn-hover ml-0">
                  <a
                      href={product.affiliateLink}
                      rel="noopener noreferrer"
                      target="_blank"
                  >
                    Buy Now
                  </a>
                </div>
              </div>
          ) : (

              <div className="pro-details-quality">
                {!marca && <div className="cart-plus-minus">
                  {!marca && <button
                      onClick={() =>
                          setQuantityMinus()

                      }
                      className="dec qtybutton"
                  >
                    -
                  </button>}
                  {!marca && <input
                      className="cart-plus-minus-box"
                      type="text"
                      value={quantityCount}
                      readOnly
                  />
                  }
                  {!marca &&
                      <button
                          onClick={() =>
                              setQuantityPlus()
                          }
                          className="inc qtybutton"
                      >
                        +
                      </button>}
                </div>
                }

                <div className="pro-details-cart btn-hover">
                  {productStock && productStock > 0 ? (

                      !marca && <button
                          onClick={() =>
                              addToCart(product,addToast,quantityCount,selectedProductColor,selectedProductSize,UpdateBanner,product.variation.find(x=>x.color===selectedProductColor).size.find(y=>y.name===selectedProductSize).prenotazione)
                          }


                          disabled={productCartQty >= productStock}
                      >
                        {" "}

                        {strings['aggiungicarrello']}{" "}



                      </button>
                  ) : (


                      <button disabled>{strings['nondisponibile']}</button>
                  )}
                </div>
                <div className="pro-details-wishlist">
                  <button
                      className={wishlistItem !== undefined ? "active" : ""}
                      disabled={wishlistItem !== undefined}
                      title={
                        wishlistItem !== undefined
                            ? "Added to wishlist"
                            : "Add to wishlist"
                      }
                      onClick={() => addToWishlist(product, addToast)}
                  >
                    {!marca && <i className="pe-7s-like" />}
                    {marca && <i className="pe-7s-like" style={{ marginLeft: "-1.8cm" }} />}
                    {marca && resize && <i className="pe-7s-like" style={{ marginLeft: "-1.4cm" }} />}
                  </button>
                </div>
                <div className="pro-details-compare">
                  <button
                      className={compareItem !== undefined ? "active" : ""}
                      disabled={compareItem !== undefined}
                      title={
                        compareItem !== undefined
                            ? "Added to compare"
                            : "Add to compare"
                      }
                      onClick={() => addToCompare(product, addToast)}
                  >
                    {!marca && <i className="pe-7s-shuffle" />}
                    {marca && <i className="pe-7s-shuffle" style={{ marginLeft: "0.4cm" }} />}
                  </button>
                </div>
              </div>
          )
        }

        <div className="pro-details-quality">
          {/*logged &&
              <div className="information-card" style={{width: "100%",marginTop: "0rem"}}>
                <div style={{marginBottom:"2vh"}}> <b>Aggiungi il prodotto ad una delle tue liste nascita!!</b></div>
                <select class="gift-lists" name="gift-lists" id="gift-list-select"  className="custom-select"
                        value={seleziona}
                        onChange={e => handleAddrTypeChange(e)}
                >
                  <option value="0">Seleziona una lista regalo…</option>
                  {liste.map((lista) => (
                      <option value={lista.codiceLista}

                      >{lista.titoloLista}</option>
                  ))}
                </select>
              </div>
          */}

        </div>
        {
          product.category ? (
              <div className="pro-details-meta">
                <span>{strings['categorie']} :</span>
                <ul>
                  {product.category.map((single, key) => {
                    return (
                        <li key={key}>
                          <Link to={process.env.PUBLIC_URL + "/categoria/" + single}>
                            {single}
                          </Link>
                        </li>
                    );
                  })}
                </ul>
              </div>
          ) : (
              ""
          )
        }
        {
          product.tag ? (

              <div className="pro-details-meta">

                <span>{strings['marca']} : </span>

                <ul>
                  {product.tag.map((single, key) => {
                    return (
                        <li key={key}>

                          {single}

                        </li>
                    );
                  })}
                </ul>
              </div>
          ) : (
              ""
          )
        }

        <div className="pro-details-social">

          <ul>
            {/*
          <li>
            <a href="//facebook.com">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="//dribbble.com">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="//pinterest.com">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
          <li>
            <a href="//twitter.com">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="//linkedin.com">
              <i className="fa fa-linkedin" />
            </a>
          </li>
          */}
          </ul>

        </div>

      </div >
  );
};

ProductDescriptionInfo.propTypes = {
  addToCart: PropTypes.func,
  addToCompare: PropTypes.func,
  addToWishlist: PropTypes.func,
  addToast: PropTypes.func,
  cartItems: PropTypes.array,
  compareItem: PropTypes.array,
  currency: PropTypes.object,
  discountedPrice: PropTypes.number,
  finalDiscountedPrice: PropTypes.number,
  finalProductPrice: PropTypes.number,
  product: PropTypes.object,
  wishlistItem: PropTypes.object,
  strings: PropTypes.object
};

const mapDispatchToProps = dispatch => {
  return {
    addToCart: (
        item,
        addToast,
        quantityCount,
        selectedProductColor,
        selectedProductSize,
        UpdateBanner,

    ) => {
      dispatch(
          addToCart(
              item,
              addToast,
              quantityCount,
              selectedProductColor,
              selectedProductSize,
              UpdateBanner,
          )
      );
    },
    addToWishlist: (item, addToast) => {
      dispatch(addToWishlist(item, addToast));
    },
    addToCompare: (item, addToast) => {
      dispatch(addToCompare(item, addToast));
    }
  };
};

export default multilanguage(connect(null, mapDispatchToProps)(ProductDescriptionInfo));
