import PropTypes from "prop-types";
import React from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { setCurrency } from "../../redux/actions/currencyActions";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import {Link} from "react-router-dom";

const HeaderTop = ({
                     currency,
                     setCurrency,
                     currentLanguageCode,
                     dispatch,
                     borderStyle,
                     strings
                   }) => {
  return (
      <div
          className={`header-top-wap ${
              borderStyle === "fluid-border" ? "border-bottom" : ""
          }`}
      >
        <LanguageCurrencyChanger
            currency={currency}
            setCurrency={setCurrency}
            currentLanguageCode={currentLanguageCode}
            dispatch={dispatch}
        />


        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
             className="header-offer">
          <Link style={{color:"white"}} to={"/lista-nascita"}>

            <div style={{
              borderRadius: "4px",
              padding: "7px",
              fontSize:"14px",
              //border: "4px solid rgb(43, 125, 233)",
              fontWeight: "bold",
              "margin-right":"10px",
               backgroundColor: "rgb(43, 125, 233)"
            }}>
              <i class="fa fa-gift" style={{fontSize: "16px"}}></i> {"LISTA NASCITA"}{" "}

            </div>
          </Link>
          <div>
            <p>
              {strings['spedizionegratis']}{" "}
            </p>
          </div>
        </div>
      </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
  setCurrency: PropTypes.func,
  currency: PropTypes.object,
  currentLanguageCode: PropTypes.string,
  dispatch: PropTypes.func,
  strings: PropTypes.object
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setCurrency: currencyName => {
      dispatch(setCurrency(currencyName));
    }
  };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(HeaderTop));
