import React from "react";
import {useState} from "react";
import { Link } from "react-router-dom";
const MobileSearch = () => {
  const [searchLink, setsearchLink] = useState("");
  const changeSearch = () => {
    setsearchLink(document.getElementById("searchmobile").value)
  }
  return (
    <div>
    {!window.location.pathname.includes("shop") &&
    <div className="offcanvas-mobile-search-area">
      <form action="#">
        <input type="text" id="searchmobile" onChange={() => changeSearch()}  placeholder="Cerca ..." />
        <Link to={process.env.PUBLIC_URL + '/shop?fromsearch=true&search=' + searchLink} >
        <button type="submit">
          <i className="fa fa-search" />
        </button>
        </Link>
      </form>
    </div>
    }
    </div>
  );
};

export default MobileSearch;
