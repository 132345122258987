import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ProductDescriptionTab from "../../wrappers/product/ProductDescriptionTab";
import ProductImageDescription from "../../wrappers/product/ProductImageDescription";
import {checkListe, getArticoli, getProdLista, inProdListaRegalo} from '../../axios/axiosCall'
import SectionTitle from "../../components/section-title/SectionTitle";
import MYNotFound from "../../pages/other/MYNotFound.js"
import ProductGrid_related from "../../wrappers/product/ProductGrid_related";
import 'react-whatsapp-widget/dist/index.css'
import BreadcrumbV2 from "../../wrappers/breadcrumb/BreadcrumbV2";
import fire from "../../firebase/firebase";
import Swal from "sweetalert2";

const Product = ({ location, product,addToast }) => {

  const { pathname } = location;
  const [prodotti, setProdotti] = useState(product);
  const [correlatedproduct, setcorrelatedproduct] = useState([]);
  const [isproduct, setisproduct] = useState(null)
  const [logged, setlogged] = useState(null);
  const [liste, setListe] = useState([])
  const [seleziona, setSeleziona] = useState([]);

  const [selectedProductColor, setSelectedProductColor] = useState(
      ""
  );
  const [selectedProductSize, setSelectedProductSize] = useState(
      ""
  );
  const [quantityCount, setQuantityCount] = useState(1);
  fire.auth().onAuthStateChanged((user) => {
    if (user) {
      setlogged(true)
    } else {
      setlogged(false)
    }
  });


  /*const filtra_correlati2 = (response, prodotto) => {
    const vector_correlated = prodotto.correlated.split(',').filter(item => item.trim() !== "");

    const prodotti_output = vector_correlated.map(correlatedId => {
      const prodotto = response.find(single => single.id == correlatedId);
      return prodotto;
    });

    return prodotti_output;
  };*/
  const filtra_correlati2 = (response,prodotto) => {


    let prodotti_output = []
    let vector_correlated = prodotto.correlated.split(',')

    for (let i = 0; i < vector_correlated.length; i++) {

      if (vector_correlated[i] === "" || vector_correlated[i] === "undefined") {

      } else {


        let prodotto = JSON.parse(JSON.stringify(response)).filter(
            single => single.id == vector_correlated[i])[0]

        prodotti_output.push(prodotto)


      }
    }

    return prodotti_output
  }


    useEffect(() => {

    let itemId = location.pathname.split("/")[2]
    let prodotto_scelto = []

    getArticoli(response => {

      prodotto_scelto = response.filter(single => single.id == itemId)[0]

      setProdotti(prodotto_scelto)

      if (prodotto_scelto == null) {

        setisproduct(false)

      }
      else {

        setisproduct(true)
        setcorrelatedproduct(filtra_correlati2(response,prodotto_scelto))
      }
    });

  }, []);

  useEffect(() => {
    var user = fire.auth().currentUser;
    if (user) {
      checkListe(response => {
        if (response.length == 0) {
        }
        else {
          setListe(response)
        }
      })
    }
  }, [logged]);

  const handleAddrTypeChange = (e) => {

    setSeleziona(e.target.value)

    if (e.target.value == "0") {

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Non hai selezionato nessuna lista',
      })
    }

    else {

      Swal.fire({
        title: 'Sto aggiungendo questo prodotto alla lista selezionata ',
        text: "Colore:" + selectedProductColor + " e quantità: " + quantityCount,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok, confermo la selezione!'
      }).then((result) => {


        if (result.isConfirmed) {

          inProdListaRegalo(e.target.value, prodotti.id, selectedProductColor, quantityCount, selectedProductSize,response => {
            console.log("ale", response)
            if (response.status == 200) {
              Swal.fire({
                icon: 'success',
                title: 'Prodotto aggiunto alla lista selezionata',
                text: 'I dati sono stati salvati correttamente.',
                confirmButtonText: 'Ok',
              });
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Errore!',
                text: 'Il prodotto non è stato aggiunto.',
                confirmButtonText: 'Riprova',
              });
            }
          })

        }
        else {
          setSeleziona([])
        }
      })

    }
  }

  const updateFather = (color,size,count) =>{


    setSelectedProductColor(color)
    setSelectedProductSize(size)
    setQuantityCount(count)
  }

  return (

      <div>


        {isproduct == true &&

            <div>
              {prodotti == undefined &&
                  <div>
                  </div>
              }
              {prodotti != undefined &&

                  <Fragment>

                    <MetaTags>
                      <title>Pagina del prodotto</title>
                      <meta name="product:brand" content="Nike" />
                      <meta name="product:condition" property="product:condition" content="new"></meta>
                      <meta name="description" content="Pagina del prodotto."/>
                    </MetaTags>

                    <a class="float" href="whatsapp://send?text=Ciao!&phone=+393895591875" >
                      <i class="fa fa-whatsapp my-float"></i>
                    </a>

                    <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
                    <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
                      DETTAGLI PRODOTTO
                    </BreadcrumbsItem>

                    <LayoutOne
                        headerTop="visible"
                        headerContainerClass="container-fluid"
                        headerPaddingClass="header-padding-1"
                    >

                      <BreadcrumbV2 />

                      <ProductImageDescription
                          spaceTopClass="pt-100"
                          spaceBottomClass="pb-40"
                          product={prodotti}
                          updateFather={updateFather}
                      />


                      <div className="information-card-with-title" style={{width: "100%",padding:"60px"}}>
                        <div className="title">{"Aggiungi il prodotto a una lista nascita".toUpperCase()}</div>
                        <select class="gift-lists" name="gift-lists" id="gift-list-select"  className="custom-select"
                                value={seleziona}
                                onChange={e => handleAddrTypeChange(e)}
                        >
                          <option value="0">Seleziona una lista regalo…</option>
                          {liste.map((lista) => (
                              <option value={lista.codiceLista}

                              >{lista.titoloLista}</option>
                          ))}
                        </select>
                      </div>

                      <div className="information-card-with-title" style={{width: "100%"}}>
                        <div className="title">{"Informazioni".toUpperCase()}</div>
                        <table className="hidden-table-maffle">
                          <tbody>
                          <tr>
                            <td><b>Lunghezza </b></td>
                            <td>{prodotti?.schedatecnica[0]['lunghezza']}</td>
                          </tr>
                          <tr>
                            <td><b>Larghezza</b></td>
                            <td>{prodotti?.schedatecnica[0]['larghezza']}</td>
                          </tr>
                          <tr>
                            <td><b>Altezza</b></td>
                            <td>{prodotti?.schedatecnica[0]['altezza']}</td>
                          </tr>
                          <tr>
                            <td><b>Peso</b></td>
                            <td>{prodotti?.schedatecnica[0]['peso']}</td>
                          </tr>
                          <tr>
                            <td><b>Materiale</b></td>
                            <td> {prodotti?.schedatecnica[0]['materiale']}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>

                      {/*<ProductDescriptionTab
                          spaceBottomClass="pb-90"
                          descrizione={prodotti.schedatecnica}
                      />*/}


                      {correlatedproduct != null && correlatedproduct.length >= 1 &&
                          <div>
                            <SectionTitle titleText="Prodotti correlati" positionClass="text-center" />
                            <div style={{ marginTop: "40px" }}  className="related-product-area pb-95">
                              <div className="container">
                                <div className="row">
                                  <ProductGrid_related
                                      prodotti={correlatedproduct}

                                      type="new"
                                      limit={8}
                                      spaceBottomClass="mb-25"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                      }

                    </LayoutOne>
                  </Fragment>
              }
            </div>
        }
        {isproduct == false &&
            <div>
              <MYNotFound />
            </div>
        }
      </div>

  );
};

Product.propTypes = {
  location: PropTypes.object,
  product: PropTypes.object,
  addToast: PropTypes.func
};


const mapStateToProps = (state, ownProps) => {
  const itemId = ownProps.match.params.id;
  let prodotti = []

  getArticoli(response => {
    prodotti = response.filter(
        single => single.id == itemId
    )[0]
    return {
      product: prodotti,
    };
  });
};

export default connect(mapStateToProps)(Product);
